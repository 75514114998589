import axios from 'axios';
import { DateFilters, TagsFilters } from '../../types/general-types';

class ScalesService {
  async getWeighingsForScale(id: string, dateFilters: DateFilters, tagsFilters: TagsFilters) {
    const result = await axios.post(`/scales/${id}/weighings`, { ...dateFilters, ...tagsFilters });

    return result.data;
  }

  async getAllWeighings(dateFilters: DateFilters) {
    const result = await axios.post(`/scales/all-weighings`, dateFilters);

    return result.data;
  }

  get() {
    return axios.get('/scales').then((res) => res.data);
  }

  getAllScalesInTheSystem() {
    return axios.get('/scales-offline/all-scales').then((res) => res.data);
  }

  delete(id) {
    return axios.delete(`/scales/${id}`);
  }

  async create(postData) {
    return axios.post(`/scales`, mapToRequestBody(postData));
  }

  async edit(postData, id) {
    return axios.put(`/scales/${id}`, mapToRequestBody(postData));
  }
}

const mapToRequestBody = (scale) => {
  const result = {
    id: scale.id,
    hostId: scale.hostId,
    divisionId: scale.divisionId,
    printerId: scale.printerId ?? null,
    name: scale.name,
    description: scale.description,
    model: scale.model,
    connectionType: scale.connectionType,
    communicationMode: scale.communicationMode,
    ipAddress: scale.ipAddress,
    port: scale.port,
    portPath: scale.portPath,
    baudRate: scale.baudRate,
    sendInterval: scale.sendInterval,
    weighingUnit: scale.weighingUnit,
    measurementAccuracy: scale.measurementAccuracy,
    measurementRegex: scale.measurementRegex,
    groupIds: scale.groupIds,
  };

  return result;
};

let scalesService = new ScalesService();
export default scalesService;
