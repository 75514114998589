export enum LicenseType {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

export interface Limits {
  usersNumber?: number;

  scalesNumber?: number;
}

export interface License {
  id: string;

  customerId: string;

  type: LicenseType;

  email?: string;

  deviceKey?: string;

  version?: string;

  installationName?: string;

  expirationDate?: Date;

  limits: Limits;

  comment?: string;

  customerName?: string;

  hasLicenseKey?: boolean;
}
