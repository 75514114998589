import React, { useEffect, useState } from 'react';
import { Scale } from './types';
import { ScalesIndicationsDashboard } from './ScalesIndicationsDashboard';
import scalesService from '../../service/api/ScalesService';
import { AxiosConfigContainer } from '../AxiosConfig';

export const ScalesIndicationsDashboardAnonymous = () => {
  const [scales, setScales] = useState<Scale[]>([]);

  const loadData = async () => {
    setScales(await scalesService.getAllScalesInTheSystem());
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <AxiosConfigContainer />
      <ScalesIndicationsDashboard scales={scales} />
    </>
  );
};
