import axios from 'axios';
import { PaginationParams, DateFilters } from '../../types/general-types';

class WeighingsService {
  async searchWeighings(
    paginationParams: PaginationParams,
    ids: string[],
    tags: string[],
    noTag: boolean,
    dateFilters: DateFilters | null
  ) {
    let dataToSend: any = {
      paginationParams,
      ids,
      tags,
      noTag,
    };

    if (dateFilters) dataToSend.dateFilters = dateFilters;

    const result = await axios.post(`/measurements/search`, dataToSend);

    return result.data;
  }

  async getTags(scaleId?: string): Promise<string[]> {
    const result = await axios.get(`/measurements/tags`, { params: { scaleId } });

    return result.data;
  }

  async getLastIndications(scalesId: string[]) {
    const result = await axios.post(`/measurements/indications`, scalesId);

    return result.data;
  }

  async getLastIndicationsForAllScales(scalesId: string[]) {
    const result = await axios.post(`/measurements/indications-all`, scalesId);

    return result.data;
  }

  async saveScaleIndication(scaleIndication: any) {
    const result = await axios.post('/measurements/save', scaleIndication);

    return result.data;
  }

  async saveScaleIndicationAndPrint(scaleIndication: any) {
    const result = await axios.post('/measurements/save-and-print', scaleIndication);

    return result.data;
  }

  async printScaleIndication(scaleIndicationId: any) {
    const result = await axios.post(`/measurements/${scaleIndicationId}/print`);

    return result.data;
  }

  async deleteScaleIndication(scaleIndicationId: string) {
    return axios.delete(`/measurements/${scaleIndicationId}`);
  }

  async getReport(
    ids: string[],
    tags: string[],
    fileFormat: string,
    isAscending: boolean,
    dateFilters: DateFilters | null
  ) {
    let dataToSend: any = {
      ids,
      tags,
      fileFormat,
      isAscending,
    };

    if (dateFilters) dataToSend.dateFilters = dateFilters;

    return axios.post(`/measurements/report`, dataToSend, {
      responseType: 'arraybuffer',
    });
  }
}

let weighingsService = new WeighingsService();
export default weighingsService;
