import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SETTINGS } from '../../enums/settings.enum';
import { ScalesIndicationsDashboardProps } from './types';
import weighingsService from '../../service/api/WeighingsService';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { scaleIndicationsActions } from '../../store/scale-indications-slice';
import { differenceInHours, parseISO } from 'date-fns';

const BACKGROUND_IMAGE_URL = '/assets/layout/images/background_image.jpg';

export const ScalesIndicationsDashboard = ({ scales }: ScalesIndicationsDashboardProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const measurements = useSelector((state: RootState) => state.scaleIndications.indications);

  const fetchMeasurements = async () => {
    const scalesId = scales.map((element: any) => element.id);

    const indications = await weighingsService.getLastIndicationsForAllScales(scalesId);

    let newMeasurements = {};

    for (const indication of indications) {
      newMeasurements[indication.scaleId] = indication;
    }

    dispatch(scaleIndicationsActions.setScaleIndications(newMeasurements));
  };

  useEffect(() => {
    fetchMeasurements();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scales, dispatch]);

  const handleBackButton = () => {
    history.push(SETTINGS.DEFAULT_PAGE);
  };

  const containerStyle = {
    background: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4)),
                 url(${BACKGROUND_IMAGE_URL}) no-repeat center center / cover`,
    width: '100%',
    minHeight: '100vh',
  };

  return (
    <div className="relative w-full min-h-screen" style={containerStyle}>
      <div className="grid w-full p-3 ml-0">
        <div className="col-12 md:col-3">
          <Button
            label={t('scalesIndicationsDashboard.back')}
            icon="pi pi-arrow-left"
            className="p-button-outlined p-button-secondary w-full md:w-auto p-3"
            onClick={handleBackButton}
          />
        </div>
      </div>

      <div className="flex-grow-1 overflow-auto p-3">
        <div className="grid justify-content-center align-items-start w-full h-full m-0">
          {scales.map((scale) => {
            const measurement = measurements[scale.id]?.value ?? '---';
            const measurementDate = measurements[scale.id]?.measurementTimeLocal ?? '---';

            const measurementTime = measurements[scale.id]?.measurementTime
              ? parseISO(measurements[scale.id]?.measurementTime)
              : null;

            const now = new Date();

            const isUpToDate = differenceInHours(now, measurementTime) < 24;

            const tileStyle = {
              backgroundColor: isUpToDate ? undefined : '#fde8e8',
            };

            //INFO: by changing sm:col-6 we can change the number of columns in the grid for small screens, col-6 stands for 2 columns (it works like 12)
            // thats is divided by 6, so 12/6 = 2 columns, if we change it to col-4 it will be 3 columns, 12/4 = 3 columns. Same for other widths (md, lg, xl etc)
            return (
              <div key={scale.id} className="col-12 sm:col-6 lg:col-3 p-2">
                <div
                  className="border-round shadow-2 surface-card p-3 flex flex-column align-items-center justify-content-center h-full"
                  style={tileStyle}
                >
                  <div className="text-xl font-bold p-3">{scale.name}</div>
                  <div className="text-2xl font-bold p-3">
                    {measurement} {scale.weighingUnit?.toLowerCase()}
                  </div>
                  <div className="mt-3">
                    {t('scales_indications.date')}: {measurementDate}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
